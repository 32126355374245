import React, { useState, useEffect } from 'react';
import Item from '../Item/Item';
import Spinner from 'react-bootstrap/Spinner';
import './MyRecommendations.css';
import Spacer from '../Spacer/Spacer';
const MyRecommendations = ({ userGender }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRecommendedProducts = async () => {
      try {
        let url = 'https://pr-web-405511.appspot.com/api/products';
        if (userGender === 'male') {
          url += '/men';
        } else if (userGender === 'female') {
          url += '/women';
        }

        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch ${userGender}'s products`);
        }

        const data = await response.json();
        setProducts(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchRecommendedProducts();
  }, [userGender]);

  if (loading) {
    return (
      <div>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const firstFourProducts = products.slice(0, 4);

  return (
    <div className='new-collections'>
      
      <Spacer/>
      <div className="collections">
        {firstFourProducts.map((item) => (
          <Item
            key={item._id}
            id={item._id}
            name={item.title}
            image={item.imageUrl}
            new_price={item.price}
          />
        ))}
      </div>
    </div>
  );
};

export default MyRecommendations;
