import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom';

import instagram_icon from '../Assets/instagram_icon.png'
import pintrest_icon from '../Assets/pintester_icon.png'


const Footer = () => {
  return (
    <div className='footer'>
      <div className="footer-logo">
        <img src={"https://res.cloudinary.com/dn3lhtixp/image/upload/v1696352744/IMG_0874_gmnvjx_mhajrs.png"} alt="" />
        
      </div>
      <ul className="footer-links">
        <li><Link to="/gallery">Gallery</Link></li>
        <li><Link to="/search">Search</Link></li>
        <li><Link to="/about">About</Link></li>
        <li><Link to="/contactform">Contact</Link></li>
      </ul>
      <div className="footer-social-icons">
      <a href='https://www.instagram.com/pathe_ravon/' >
        <div className="footer-icons-container">
          
            <img src={instagram_icon} alt="" />
            
        </div>
        </a>
        <div className="footer-icons-container">
          <a href='https://www.instagram.com/pathe_ravon/' >
            <img src={pintrest_icon} alt="" />
            </a>
        </div>
      </div>
      <div className="footer-copyright">
        <hr />
        <p>Copyright @ 2024 - Pathe Ravon</p>
      </div>
    </div>
  )
}

export default Footer
