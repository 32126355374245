import React from 'react';
import './DialogFlowMessenger.css';
const DialogflowMessenger = () => {
  return (
    <df-messenger
      location="us-central1"
      project-id="pr-web-405511"
      agent-id="ce69912b-9056-4586-af8a-dcf009b97e65"
      language-code="en"
      max-query-length="-1">
      <df-messenger-chat-bubble
       chat-title="Pr Web Support ">
      </df-messenger-chat-bubble>
    </df-messenger>
  );
};

export default DialogflowMessenger;
