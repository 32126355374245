import React from 'react';
import DialogflowMessenger from '../Components/DialogFlowMessenger/DialogFlowMessenger'
import Carousel from '../Components/Carousel/Carousel';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Spacer from '../Components/Spacer/Spacer';



const Gallery = () => {
    const images = [
        'https://res.cloudinary.com/dn3lhtixp/image/upload/h_400/v1705813046/IMG_0240_aaulkd.jpg',
        'https://res.cloudinary.com/dn3lhtixp/image/upload/h_400/v1705813046/IMG_0238_glnu2z.jpg',
        'https://res.cloudinary.com/dn3lhtixp/image/upload/h_400/v1705813039/IMG_0235_zzwifw.jpg',
        'https://res.cloudinary.com/dn3lhtixp/image/upload/h_400/v1705813046/IMG_0236_c5jvpi.jpg',
      ];

     
      
      const itemData = [
        {
          img: 'https://res.cloudinary.com/dn3lhtixp/image/upload/v1705813053/IMG_4969_rxyuwe.jpg',
          title: '',
          author: '',
        },
        {
          img: 'https://res.cloudinary.com/dn3lhtixp/image/upload/v1705813053/IMG_0381_nuwmzr.jpg',
          title: '',
          author: '',
        },
        {
          img: 'https://res.cloudinary.com/dn3lhtixp/image/upload/v1705813053/IMG_4968_ol4hgd.jpg',
          title: '',
          author: '',
        },
        {
          img: 'https://res.cloudinary.com/dn3lhtixp/image/upload/v1705813053/IMG_0825_dpb4pd.jpg',
          title: '',
          author: '',
        },
        {
          img: 'https://res.cloudinary.com/dn3lhtixp/image/upload/v1705813046/IMG_0239_sbu31u.jpg',
          title: '',
          author: '',
        },
        {
          img: 'https://res.cloudinary.com/dn3lhtixp/image/upload/v1705813038/E9309EDD-5E8A-443A-AD95-449D1179F20C_eejirp.jpg',
          title: '',
          author: '',
        },
        {
          img: 'https://res.cloudinary.com/dn3lhtixp/image/upload/v1705813038/7753A9E2-4DFD-466B-B5E4-01F76F3F3DB8_lot4fh.jpg',
          title: '',
          author: '',
        },
        {
          img: 'https://res.cloudinary.com/dn3lhtixp/image/upload/v1705813038/3276C373-E911-479A-827E-F53E18199D1F_liqsnb.jpg',
          title: '',
          author: '',
        },
        {
          img: 'https://res.cloudinary.com/dn3lhtixp/image/upload/v1705813038/8A2254B5-87DC-45D2-BC18-DD7BFC3C8DCB_vxolii.jpg',
          title: 'Coffee',
          author: '',
        },
        {
          img: 'https://res.cloudinary.com/dn3lhtixp/image/upload/v1705813037/6B09D96A-BEDF-4EDC-97AC-B50239F6AF15_oezurh.jpg',
          title: '',
          author: '',
        },
        {
          img: 'https://res.cloudinary.com/dn3lhtixp/image/upload/v1704496439/IMG_0272_r52f3m.jpg',
          title: '',
          author: '',
        },
        {
          img: 'https://res.cloudinary.com/dn3lhtixp/image/upload/v1705813038/26F70488-421F-422A-B109-BBD7068D8986_nfss8s.jpg',
          title: '',
          author: '',
        },
      ];
      
  return (
    <div>
    <Carousel images={images} />
    <Spacer/>
    <div style={{display:'flex', justifyContent:'center'}}>
    <Box sx={{ justifyContent:'center', width: 1280, height: 450, overflowY: 'scroll' }}>
      <ImageList variant="masonry" cols={3} gap={8}>
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.img}?w=248&fit=crop&auto=format`}
              alt={item.title}
              loading="lazy"
            />
            <ImageListItemBar position="below" title={item.author} />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
    
    </div>
    
    <DialogflowMessenger/>
    </div>
  );
};

export default Gallery;