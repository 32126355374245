import React, { useContext, useEffect, useRef, useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import logo from "../Assets/logo.png";
import cart_icon from "../Assets/cart_icon.png";
import { ShopContext } from "../../Context/ShopContext";
import nav_dropdown from "../Assets/nav_dropdown.png";
import LoginButton from "../LoginButton/LoginButton";
import { useAuth0 } from "@auth0/auth0-react";

const Navbar = () => {
  const [menu, setMenu] = useState("shop");
  const { getTotalCartItems } = useContext(ShopContext);
  const { isAuthenticated, logout, user } = useAuth0();
  const menuRef = useRef();
  const [cartCount, setCartCount] = useState(0);

  const dropdown_toggle = (e) => {
    menuRef.current.classList.toggle("nav-menu-visible");
    e.target.classList.toggle("open");
  };

  useEffect(() => {
    if (isAuthenticated) {
      getTotalCartItems()
        .then((count) => {
          setCartCount(count);
        })
        .catch((error) => {
          console.error("Error fetching cart items:", error);
          // Handle error if needed
        });
    }
  }, [isAuthenticated, getTotalCartItems]);

  return (
    <div className="nav">
      <Link
        to="/"
        onClick={() => {
          setMenu("shop");
        }}
        style={{ textDecoration: "none" }}
        className="nav-logo"
      >
        <img src={logo} alt="logo" />
      </Link>
      <img
        onClick={dropdown_toggle}
        className="nav-dropdown"
        src={nav_dropdown}
        alt=""
      />
      <ul ref={menuRef} className="nav-menu">
        <li
          onClick={() => {
            setMenu("mens");
          }}
        >
          <Link to="/mens" style={{ textDecoration: "none" }}>
            Men
          </Link>
          {menu === "mens" ? <hr /> : <></>}
        </li>
        <li
          onClick={() => {
            setMenu("womens");
          }}
        >
          <Link to="/womens" style={{ textDecoration: "none" }}>
            Women
          </Link>
          {menu === "womens" ? <hr /> : <></>}
        </li>
        <li
          onClick={() => {
            setMenu("accessories");
          }}
        >
          <Link to="/accessories" style={{ textDecoration: "none" }}>
            Accessories
          </Link>
          {menu === "accessories" ? <hr /> : <></>}
        </li>
        <li
          onClick={() => {
            setMenu("shoes");
          }}
        >
          <Link to="/shoes" style={{ textDecoration: "none" }}>
            Shoes
          </Link>
          {menu === "shoes" ? <hr /> : <></>}
        </li>
      </ul>
      <div className="nav-login-cart">
        {isAuthenticated ? (
          <>
            <button
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              Logout
            </button>
            <Link to="/profile" style={{ textDecoration: "none" }}>
              <span className="user-name">{user.name}</span>
            </Link>
          </>
        ) : (
          <LoginButton />
        )}
        <Link to="/cart">
          <img src={cart_icon} alt="cart" />
        </Link>
        <div className="nav-cart-count">{cartCount}</div>
      </div>
    </div>
  );
};

export default Navbar;
