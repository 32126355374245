import React, { useContext, useEffect, useState } from "react";
import "./CartItems.css";
import cross_icon from "../Assets/cart_cross_icon.png";
import { ShopContext } from "../../Context/ShopContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from 'react-router-dom';

const CartItems = () => {
  const { isAuthenticated, user } = useAuth0();
  const { removeFromCart } = useContext(ShopContext);
  const [cartProducts, setCartProducts] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const calculateSubtotal = () => {
      let total = 0;
      cartProducts.forEach((product) => {
        if (product.cartItem) {
          total += product.cartItem.price * product.quantity;
        }
      });
      setSubtotal(total);
    };

    calculateSubtotal();
  }, [cartProducts]);


  const fetchCartItems = async (userId) => {
    try {
      const response = await fetch(
        `https://pr-web-405511.appspot.com/api/cart/find/${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch cart");
      }

      const cartData = await response.json();
     

      // Check if cartData contains a products array
      if (cartData && cartData.length > 0 && cartData[0].products) {
        const cartItems = cartData[0].products;
        return cartItems;
      } else {
        throw new Error("Cart data does not contain products array");
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
    }
  };

  const fetchProductDetails = async (productId) => {
    try {
      const response = await fetch(
        `https://pr-web-405511.appspot.com/api/products/${productId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch product details");
      }
      const productDetails = await response.json();
      return productDetails;
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  useEffect(() => {
    const fetchCartData = async () => {
      if (isAuthenticated && user) {
        const userId = user.sub;                  
        const cartData = await fetchCartItems(userId);
        if (cartData && cartData.length > 0) {
          const cartItemArray = cartData;
          const products = await Promise.all(
            cartItemArray.map(async (product) => {
              // Check if product.cartItem exists before fetching details
              if (product.cartItem) {
                const productDetails = await fetchProductDetails(
                  product.cartItem._id
                );
                return {
                  ...product,
                  cartItem: productDetails, // Replace cartItem with productDetails fetched from API
                };
              } else {
                return product; // Return product as is if cartItem is null or undefined
              }
            })
          );
          setCartProducts(products);
  
          // Construct array for line items with quantity
          const lineItems = products.map((product) => ({
            price: product.cartItem ? product.cartItem.stripe_price_id : null, // Use null if cartItem is null
            quantity: product.quantity, // Store the quantity
          }));
          
  
          // Store line items to the server
          storeLineItemsToServer(userId, lineItems); // Pass userId to store function
        }
      }
    };
  
    fetchCartData();
  }, [isAuthenticated, user]);
  
  
  const storeLineItemsToServer = async (userId, lineItems) => {
   
    try {
      const response = await fetch(`https://pr-web-405511.appspot.com/api/lineItems/${userId}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("auth-token"),
        
        },
        body: JSON.stringify({ userId: userId, lineItems }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to store line items');
      }
  
    } catch (error) {
      console.error('Error storing line items:', error);
    }
  };
  
  
  
  
  
  

  const handleProceedToShipping = () => {
    window.location.href = "/shipping";
  };

  const renderProceedToShippingButton = () => {
    if (location.pathname === "/cart") {
      return <button onClick={handleProceedToShipping}>PROCEED TO SHIPPING</button>;
    }
    return null;
  };

  return (
    <div className="cartitems">
      <div className="cartitems-format-main">
        <p>Products</p>
        <p>Title</p>
        <p>Price</p>
        <p>Quantity</p>
        <p>Total</p>
        <p>Remove</p>
      </div>
      <hr />
      {cartProducts.map((product) => (
        <div key={product._id}>
          <div className="cartitems-format-main cartitems-format">
            {product.cartItem && (
              <>
                <img
                  className="cartitems-product-icon"
                  src={product.cartItem.imageUrl}
                  alt=""
                />
                <p className="cartitems-product-title">
                  {product.cartItem.title}
                </p>
                <p>${product.cartItem.price}</p>
              </>
            )}
            <button className="cartitems-quantity">{product.quantity}</button>
            {product.cartItem && (
              <p>${product.cartItem.price * product.quantity}</p>
            )}
            <img
              onClick={() => removeFromCart(product._id)}
              className="cartitems-remove-icon"
              src={cross_icon}
              alt=""
            />
          </div>
          <hr />
        </div>
      ))}
      <div className="cartitems-down">
        <div className="cartitems-total">
          <h1>Cart Totals</h1>
          <div>
            <div className="cartitems-total-item">
              <p>Subtotal</p>
              <p>${subtotal.toFixed(2)}</p>
            </div>
            <hr />
            <div className="cartitems-total-item">
              <p>Shipping Fee</p>
              <p>TBD</p>
            </div>
            <hr />
            <div className="cartitems-total-item">
              <h3>Total</h3>
              <h3>${subtotal.toFixed(2)}</h3>
            </div>
          </div>
          {renderProceedToShippingButton()}
        </div>
        <div className="cartitems-promocode">
          <p>If you have a promo code, Enter it here</p>
          <div className="cartitems-promobox">
            <input type="text" placeholder="promo code" />
            <button>Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItems;