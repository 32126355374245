import React from 'react'
import CartItems from '../Components/CartItems/CartItems'
import DialogflowMessenger from '../Components/DialogFlowMessenger/DialogFlowMessenger'
import ShippingForm from '../Components/ShippingForm/ShippingForm'
import Spacer from '../Components/Spacer/Spacer'

const Shipping = () => {
  return (
    <div>
       <Spacer /> 
      <ShippingForm/>
      <CartItems/>
      <DialogflowMessenger/>
    </div>
  )
}

export default Shipping
