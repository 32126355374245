import React, { useState, useEffect } from "react";
import "./ShippingOptions.css";
import { useAuth0 } from "@auth0/auth0-react";

const ShippingOptions = () => {
  const [shippingOptions, setShippingOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [lineItemsFromServer, setLineItemsFromServer] = useState([]);
  const { isAuthenticated, user } = useAuth0();
  const [addressFromServer, setAddressFromServer] = useState({});

  const userId = isAuthenticated && user.sub ? user.sub : null;

  useEffect(() => {
    const getAddressResponse = async (userId) => {
      try {
        const response = await fetch(`https://pr-web-405511.appspot.com/api/shippingAddress/find/${userId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch address");
        }

        const data = await response.json();
        console.log("Address Response:", data);
        setAddressFromServer(data);
      } catch (error) {
        console.error("Error fetching address:", error);
      }
    };

    if (userId) {
      getAddressResponse(userId);
      fetchLineItemsFromServer(userId);
    } else {
      console.log("No address found (No User)");
    }
  }, [userId]);

  useEffect(() => {
    const fetchShippingOptions = async () => {
      try {
        // Check if addressFromServer is populated
        if (!Object.keys(addressFromServer).length) {
          console.log("Address from server is not loaded yet.");
          return;
        }
  
        console.log("Address from server:", addressFromServer);
        const response = await fetch("https://pr-web-405511.appspot.com/get-shippo-rates", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            addressTo: addressFromServer,
          })
        });
  
        if (!response.ok) {
          throw new Error("Failed to fetch shipping options");
        }
  
        const data = await response.json();
        console.log("Shipping Options:", data.shipment.rates);
        setShippingOptions(data.shipment.rates);
      } catch (error) {
        console.error("Error fetching shipping options:", error);
      }
    };
  
    if (Object.keys(addressFromServer).length > 0) {
      fetchShippingOptions(); // Call fetchShippingOptions only if addressFromServer is loaded
    }
  }, [addressFromServer]);
  

  const fetchLineItemsFromServer = async (userId) => {
    try {
      const response = await fetch(`https://pr-web-405511.appspot.com/api/lineItems/find/${userId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch line items");
      }

      const data = await response.json();
      console.log("Line Items from Server:", data);
      setLineItemsFromServer(data[0].lineItems);
    } catch (error) {
      console.error("Error fetching line items:", error);
    }
  };

  const handleSelectOption = (option) => {
    setSelectedOption(option);
  };

  const handleSubmit = async () => {
    try {
      if (!selectedOption) {
        throw new Error("Please select a shipping option");
      }

      console.log("Line Items:", lineItemsFromServer);

      const response = await fetch("https://pr-web-405511.appspot.com/create-checkout-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          lineItems: lineItemsFromServer,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to create checkout session");
      }

      const data = await response.json();
      window.location.href = data.url;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <h2 style={{ textAlign: "center", marginBottom: "20px" }}>Shipping Options</h2>
      <div className="shipping-options-container">
        {shippingOptions.map((option, index) => (
          <div key={index} className={`shipping-option ${selectedOption === option ? 'selected' : ''}`}>
            <button
              style={{ backgroundColor: "red", color: "white" }}
              onClick={() => handleSelectOption(option)}
            >
              {option.provider}
            </button>
            <div className="shipping-option-details">
              <p><span>Carrier:</span> <strong>{option.provider}</strong></p>
              <p><em>Delivery Time:</em> <i>{option.duration_terms}</i></p>
              <p><u>Price:</u> <strong><ins>{option.amount} {option.currency}</ins></strong></p>
            </div>
          </div>
        ))}
      </div>
      {selectedOption && (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <h3>Selected Shipping Option:</h3>
          <p><strong>Carrier:</strong> {selectedOption.provider}</p>
          <p><em>Delivery Time:</em> {selectedOption.duration_terms}</p>
          <p><u>Price:</u> <strong><ins>{selectedOption.amount} {selectedOption.currency}</ins></strong></p>
        </div>
      )}
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
};

export default ShippingOptions;
