import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DialogflowMessenger from '../Components/DialogFlowMessenger/DialogFlowMessenger';
import FormControl from '@mui/material/FormControl';
import Spacer from '../Components/Spacer/Spacer';
import Item from '../Components/Item/Item';
import Spinner from 'react-bootstrap/Spinner';

const SearchPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSearch = async () => {
    setLoading(true);
    setError(null);
    try {
      
      const response = await fetch(`https://pr-web-405511.appspot.com/api/products/search/${searchTerm}`);
      if (!response.ok) {
        throw new Error('Failed to fetch search results');
      }
      const data = await response.json();
      setSearchResults(data);
      
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  };

  return (
    <div>
      <Spacer/>
      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          '& > :not(style)': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      > 
        <FormControl sx={{ m: 1, justifyContent: 'center', alignItems: 'center', width: '25ch' , color: 'black'}}>
          <TextField 
            variant="filled"  
            focused 
            value={searchTerm} 
            onChange={(e) => setSearchTerm(e.target.value)} 
          />
          <Button 
            variant='contained'  
            color="inherit" 
            sx={{ mt: 2 }} 
            onClick={handleSearch} // Call handleSearch function when button is clicked
          >
            Search
          </Button>
        </FormControl>
      </Box>

      <div className="collections">
        {loading ? (
           <Spinner animation="border" />
        ) : error ? (
          <div>Error: {error}</div>
        ) : (
          searchResults.map((item, i) => (
            <Item
              key={item._id}
              id={item._id}
              name={item.title}
              image={item.imageUrl}
              new_price={item.price}
            />
          ))
        )}
      </div>

      <DialogflowMessenger/>
    </div>
  );
};

export default SearchPage;
