import React, { useState, useEffect } from 'react';
import Item from '../Item/Item';
import './NewCollections.css'; // Import your CSS file

import Spinner from 'react-bootstrap/Spinner';

const NewCollections = () => {
  const [menProducts, setMenProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMenProducts = async () => {
      try {
        const response = await fetch('https://pr-web-405511.appspot.com/api/products/men');
        if (!response.ok) {
          throw new Error('Failed to fetch men\'s products');
        }
        const data = await response.json();
        setMenProducts(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchMenProducts();
  }, []);

  if (loading) {
    return (
      <div>
        {loading ? <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner> : <div>Your content when not loading</div>}
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const firstThreeMenProducts = menProducts.slice(0, 4);

  return (
    <div className='new-collections'>
      <h1>NEW IN MEN</h1>
      <hr />
      <div className="collections">
        {firstThreeMenProducts.map((item, i) => (
          <Item
            key={item._id}
            id={item._id}
            name={item.title}
            image={item.imageUrl}
            new_price={item.price}
          />
        ))}
      </div>
    </div>
  );
};

export default NewCollections;
