import React, { useContext, useEffect, useState } from "react";
import "./ProductDisplay.css";
import star_icon from "../Assets/star_icon.png";
import star_dull_icon from "../Assets/star_dull_icon.png";
import { ShopContext } from "../../Context/ShopContext";
import { useAuth0 } from "@auth0/auth0-react";
import Spinner from "react-bootstrap/esm/Spinner";
import { useParams } from "react-router-dom";

const ProductDisplay = () => {
  const { productId } = useParams();
  const [productDetails, setProductDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [inventory, setInventory] = useState([]);
  const { addToCart } = useContext(ShopContext);
  const { isAuthenticated } = useAuth0();
  const [isProductAvailable, setIsProductAvailable] = useState(true);

  const handleSizeSelect = (size) => {
    setSelectedSize(size);
  };

  useEffect(() => {
    const fetchProductDetails = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://pr-web-405511.appspot.com/api/products/${productId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch product details");
        }
        const data = await response.json();
        setProductDetails(data);
        setInventory(data.inventory); // Set inventory from fetched data

        // Check if there's any inventory available
        const availableSizes = data.inventory.filter(item => item.quantity > 0);
        setIsProductAvailable(availableSizes.length > 0);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [productId]);

  const handleAddToCart = async () => {
    if (!isAuthenticated) {
      window.alert("Please log in to add items to the cart.");
      return;
    }
    if (!selectedSize) {
      window.alert("Please select a size before adding to cart.");
      return;
    }
    const selectedInventory = inventory.find(item => item.size === selectedSize);
    if (selectedInventory && selectedInventory.quantity > 0) {
      try {
        const response = await addToCart(productDetails._id, selectedSize);
        if (response === "Product added to cart") {
          window.alert("Item successfully added to cart!");
        } else {
          window.alert(
            "Failed to add item to cart. Please try again later."
          );
        }
      } catch (error) {
        console.error("Error adding item to cart:", error);
        window.alert("Failed to add item to cart. Please try again later.");
      }
    } else {
      window.alert("Selected size is currently out of stock.");
    }
  };

  if (loading) {
    return <Spinner animation="border" />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="productdisplay">
      {productDetails && (
        <div className="productdisplay-left">
          <div className="productdisplay-img-list">
            <img src={productDetails.imageUrl} alt="img" />
            <img src={productDetails.imageUrl2} alt="img" />
            <img src={productDetails.imageUrl3} alt="img" />
            <img src={productDetails.imageUrl4} alt="img" />
          </div>
          <div className="productdisplay-img">
            <img
              className="productdisplay-main-img"
              src={productDetails.imageUrl}
              alt="img"
            />
          </div>
        </div>
      )}
      <div className="productdisplay-right">
        {productDetails && (
          <>
            <h1>{productDetails.title}</h1>
            <div className="productdisplay-right-stars">
              <img src={star_icon} alt="" />
              <img src={star_icon} alt="" />
              <img src={star_icon} alt="" />
              <img src={star_icon} alt="" />
              <img src={star_dull_icon} alt="" />
              <p>(122)</p>
            </div>
            <div className="productdisplay-right-prices">
              <div className="productdisplay-right-price-new">
                ${productDetails.price}
              </div>
            </div>
            <div className="productdisplay-right-description">
              {productDetails.description}
            </div>
            {isProductAvailable && (
              <div className="productdisplay-right-size">
                <h1>Select Size</h1>
                <div className="productdisplay-right-sizes">
                  {inventory.map(item =>
                    item.quantity > 0 ? (
                      <div
                        key={item.size}
                        className={selectedSize === item.size ? "selected" : ""}
                        onClick={() => handleSizeSelect(item.size)}
                      >
                        {item.size.toUpperCase()}
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            )}
            {!isProductAvailable && (
              <p className="productdisplay-right-sold-out">This product is currently sold out.</p>
            )}
            {selectedSize && isProductAvailable && (
              <button onClick={handleAddToCart}>
                {inventory.find(item => item.size === selectedSize)?.quantity === 0 ? "SOLD OUT" : "ADD TO CART"}
              </button>
            )}
            <p className="productdisplay-right-category">
              <span>Category :</span> {productDetails.category}
            </p>
            <p className="productdisplay-right-category">
              <span>Tags :</span> {productDetails.subCategory}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default ProductDisplay;
