import React, { useState, useEffect } from 'react';
import Item from '../Item/Item';
import './RelatedProducts.css'; // Import your CSS file
import { useParams } from'react-router-dom';
import Spinner from 'react-bootstrap/esm/Spinner';


const RelatedProducts = () => {
  const [relatedProducts, setRelatedProducts] = useState([]);
  const { productId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [category, setCategory] = useState(null);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        // Fetch product details using productId
        const response = await fetch(`https://pr-web-405511.appspot.com/api/products/${productId}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch product details for product ${productId}`);
        }
        const data = await response.json();
        setCategory(data.category); // Set the category from the retrieved product details
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [productId]);

  useEffect(() => {
    if (category) {
      const fetchRelatedProducts = async () => {
        try {
          // Fetch related products based on category
          const response = await fetch(`https://pr-web-405511.appspot.com/api/products/${category}`);
          if (!response.ok) {
            throw new Error(`Failed to fetch related products for category ${category}`);
          }
          const data = await response.json();
          // Exclude the current product from related products
          const filteredProducts = data.filter(product => product._id !== productId);
          setRelatedProducts(filteredProducts);
          setLoading(false);
        } catch (error) {
          setError(error.message);
          setLoading(false);
        }
      };

      fetchRelatedProducts();
    }
  }, [category, productId]);

  if (loading) {
    return (
      <div>
        {loading ? <Spinner animation="border" /> : <div>Your content when not loading</div>}
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Function to shuffle array
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  // Select four random products from the related products
  const randomRelatedProducts = shuffleArray(relatedProducts).slice(0, 4);

  return (
    <div className='relatedproducts'>
      <h1>Related Products</h1>
      <hr />
      <div className="relatedproducts-item">
        {randomRelatedProducts.map((item) => (
          <Item
            key={item._id}
            id={item._id}
            name={item.title}
            image={item.imageUrl}
            new_price={item.price}
          />
        ))}
      </div>
    </div>
  );
};

export default RelatedProducts;
