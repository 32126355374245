import React from 'react';

import Breadcrums from '../Components/Breadcrums/Breadcrums';
import ProductDisplay from '../Components/ProductDisplay/ProductDisplay';
import Spacer from '../Components/Spacer/Spacer';
import DialogflowMessenger from '../Components/DialogFlowMessenger/DialogFlowMessenger';

import RelatedProducts from '../Components/RelatedProducts/RelatedProducts';
import { useParams } from 'react-router-dom';


const Product = () => {

  const { productId } = useParams();

  return (
    <div>
      <Breadcrums />
      <ProductDisplay productId={productId} /> {/* Pass productId as a prop */}
      <Spacer /> 
      <RelatedProducts/>
      <DialogflowMessenger/>
    </div>
  );
};

export default Product;
