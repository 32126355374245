import React, { useState, useEffect } from "react";
import DialogflowMessenger from "../Components/DialogFlowMessenger/DialogFlowMessenger";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import SpacerTwo from "../Components/SpacerTwo/SpacerTwo";
import Spacer from "../Components/Spacer/Spacer";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Spinner from "react-bootstrap/Spinner";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ImageIcon from "@mui/icons-material/Image";
import { Link } from "react-router-dom";
import WorkIcon from "@mui/icons-material/Work";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import MyRecommendations from "../Components/MyRecommendations/MyRecommendations";

import { useAuth0 } from "@auth0/auth0-react";

const ProfilePage = () => {
  const { user } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);
  const [productDetails, setProductDetails] = useState({}); // State to store product details
  const [userData, setUserData] = useState(null); // State to store user data
  const [userGender, setUserGender] = useState(null);
  const [activeSection, setActiveSection] = useState("orders");

  useEffect(() => {
    const fetchUserOrders = async () => {
      setLoading(true);
      setError(null);
      try {
        const userId = encodeURIComponent(user.sub);
        const response = await fetch(
          `https://pr-web-405511.appspot.com/api/orders/user-order-history/${userId}`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch user orders");
        }

        const data = await response.json();
        setOrders(data);

        // Fetch product details for each product in orders
        const productIds = data.reduce((ids, order) => {
          order.products.forEach((product) => {
            ids.add(product.productId); // Collect unique productIds
          });
          return ids;
        }, new Set());

        // Fetch product details for each productId
        const productPromises = Array.from(productIds).map(
          async (productId) => {
            const productResponse = await fetch(
              `https://pr-web-405511.appspot.com/api/products/${productId}`
            );
            if (!productResponse.ok) {
              throw new Error(
                `Failed to fetch product details for productId: ${productId}`
              );
            }
            const productData = await productResponse.json();
            return { [productId]: productData }; // Return object with productId as key
          }
        );

        const resolvedProductDetails = await Promise.all(productPromises);
        const productDetailsMap = resolvedProductDetails.reduce(
          (details, product) => {
            const productId = Object.keys(product)[0];
            details[productId] = product[productId];
            return details;
          },
          {}
        );

        setProductDetails(productDetailsMap);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchUserData = async () => {
      setLoading(true);
      setError(null);
      try {
        const userId = encodeURIComponent(user.sub);
        const response = await fetch(
          `https://pr-web-405511.appspot.com/api/users/${userId}`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }

        const userData = await response.json();
        setUserData(userData);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchUserGender = async () => {
      try {
        const response = await fetch(
          `https://pr-web-405511.appspot.com/api/users/${user.sub}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch user gender");
        }
        const userData = await response.json();
        setUserGender(userData.gender);
      } catch (error) {
        setError(error.message);
      }
    };

    if (user) {
      fetchUserOrders();
      fetchUserData();
      fetchUserGender();
    }
  }, [user]);

  const stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: "#d50000",
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  };

  return (
    <div>
      <Spacer />
      <img
        src="https://res.cloudinary.com/dn3lhtixp/image/upload/v1696352744/IMG_0873_nyulwj_n6d4vm.png"
        alt="Banner"
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          width: "auto",
          maxHeight: "200px",
          objectFit: "cover",
          verticalAlign: "middle",
        }}
      />

      <div style={{ display: "flex", justifyContent: "center" }}>
        <p style={{ fontSize: "15px" }}>{user ? user.name : "Loading..."}</p>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Stack direction="row" spacing={2}>
          <Avatar {...stringAvatar(user ? user.name : "Loading...")} />
        </Stack>
      </div>
      <SpacerTwo />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ButtonGroup variant="outlined" aria-label="Basic button group">
          <Button
            sx={{
              color: "inherit",
              "&:hover": {
                backgroundColor: "red",
                color: "white",
                borderColor: "black",
              },
              "&.MuiButton-contained.Mui-active": {
                backgroundColor: "red",
                color: "white",
                borderColor: "black",
              },
              borderColor: "black",
            }}
            onClick={() => setActiveSection("orders")}
            className={activeSection === "orders" ? "active" : ""}
          >
            My Orders
          </Button>
          <Button
            sx={{
              color: "inherit",
              "&:hover": {
                backgroundColor: "red",
                color: "white",
                borderColor: "black",
              },
              "&.MuiButton-contained.Mui-active": {
                backgroundColor: "red",
                color: "white",
                borderColor: "black",
              },
              borderColor: "black",
            }}
            onClick={() => setActiveSection("recommendations")}
            className={activeSection === "recommendations" ? "active" : ""}
          >
            My Recommendations
          </Button>

          <Button
            sx={{
              color: "inherit",
              "&:hover": {
                backgroundColor: "red",
                color: "white",
                borderColor: "black",
              },
              "&.MuiButton-contained.Mui-active": {
                backgroundColor: "red",
                color: "white",
                borderColor: "black",
              },
              borderColor: "black",
            }}
            onClick={() => setActiveSection("profile")}
            className={activeSection === "profile" ? "active" : ""}
          >
            Profile
          </Button>
        </ButtonGroup>
      </div>
      <Spacer />
      {activeSection === "orders" && (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h2 style={{ color: "#e10000" }}>Order History</h2>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Spacer />

            <div className="orders">
              {loading ? (
                <Spinner animation="border" />
              ) : error ? (
                <div>Error: {error}</div>
              ) : (
                orders.map((orders) => (
                  <div key={orders._id} className="order-item">
                    <SpacerTwo />
                    <h3>Order ID: {orders.orderNumber}</h3>
                    <p>Order Date: {orders.orderDate}</p>
                    <ul>
                      {orders.products.map((product) => (
                        <li key={product.productId}>
                          {product.quantity} x{" "}
                          {productDetails[product.productId]?.title} - $
                          {productDetails[product.productId]?.price *
                            product.quantity}
                        </li>
                      ))}
                    </ul>
                    <p>Subtotal: ${orders.subtotal}</p>
                    <p>Total: ${orders.total}</p>
                    <p>Delivery Status: {orders.deliveryStatus}</p>
                    <p>Payment Status: {orders.paymentStatus}</p>
                  </div>
                ))
              )}
            </div>
          </div>
        </>
      )}
      {activeSection === "profile" && (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h2 style={{ color: "#e10000" }}>Profile Information</h2>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Title"
                  secondary={userData ? userData.title : " "}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <WorkIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="First Name"
                  secondary={userData ? userData.firstName : " "}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <BeachAccessIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Last Name"
                  secondary={userData ? userData.lastName : " "}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Country"
                  secondary={userData ? userData.country : " "}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <WorkIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Gender"
                  secondary={userData ? userData.gender : " "}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <BeachAccessIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Date of Birth"
                  secondary={
                    userData
                      ? new Date(userData.dateOfBirth).toLocaleDateString()
                      : " "
                  }
                />
              </ListItem>
            </List>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ButtonGroup variant="outlined" aria-label="Basic button group">
              <Button
                component={Link}
                to="/updateprofile"
                sx={{
                  color: "inherit",
                  "&:hover": {
                    backgroundColor: "red",
                    color: "white",
                    borderColor: "black",
                  },
                  "&.MuiButton-contained.Mui-active": {
                    backgroundColor: "red",
                    color: "white",
                    borderColor: "black",
                  },
                  borderColor: "black",
                }}
              >
                Update Profile
              </Button>
            </ButtonGroup>
          </div>
        </>
      )}
      {activeSection === "recommendations" && (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h2 style={{ color: "#e10000" }}>Recommended for You</h2>
          </div>
          <MyRecommendations userGender={userGender} />
        </>
      )}
      <DialogflowMessenger />
    </div>
  );
};

export default ProfilePage;
