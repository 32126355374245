import React, { useEffect, useState } from 'react';
import './Breadcrums.css';
import { useParams } from 'react-router-dom';
import arrow_icon from '../Assets/breadcrum_arrow.png';

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const Breadcrums = () => {
  const { productId } = useParams();
  const [loading, setLoading] = useState(false);
  const [productDetails, setProductDetails] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProductDetails = async () => {
      setLoading(true);
      try {
        const response = await fetch(`https://pr-web-405511.appspot.com/api/products/${productId}`);
        if (!response.ok) {
          throw new Error("Failed to fetch product details");
        }
        const data = await response.json();
        setProductDetails(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [productId]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className='breadcrums'>
      HOME <img src={arrow_icon} alt="" /> SHOP <img src={arrow_icon} alt="" /> {productDetails && productDetails.category} {/* Render product category */}
      <img src={arrow_icon} alt="" /> {productDetails && productDetails.title} {/* Render product title */}
    </div>
  );
}

export default Breadcrums;
