import React from "react";
import "./Hero.css";

import arrow_icon from "../Assets/arrow.png";

const Hero = () => {
  return (
    <div className="hero">
      <div className="hero-left">
        <div>
          <p>Designed</p>
          <p>By Vetement Designer</p>
        </div>
        <a href="/womens">
        <div className="hero-latest-btn">
          <div>Latest Collection</div>
          <img src={arrow_icon} alt="" />
        </div>
        </a>
      </div>
      <div className="hero-right">
        <img src={"https://res.cloudinary.com/dn3lhtixp/image/upload/v1711244643/Untitled_design_l6rzfl.png"} alt="hero" />
      </div>
    </div>
  );
};

export default Hero;
