import React from 'react';
import CartItems from '../Components/CartItems/CartItems';
import DialogflowMessenger from '../Components/DialogFlowMessenger/DialogFlowMessenger';
import ShippingOptions from '../Components/ShippingOptions/ShippingOptions';
import Spacer from '../Components/Spacer/Spacer';

const ShippingChoice = () => {
  return (
    <div>
      <Spacer /> 
      <ShippingOptions />
      <CartItems />
      <DialogflowMessenger />
    </div>
  );
};

export default ShippingChoice;