import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import ShopContextProvider from './Context/ShopContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <Auth0Provider
    domain='https://dev-27yanydhd6s7l0pf.us.auth0.com'
    clientId='terp292PiB8wE1cB43qcHvUsjGTQMBOc'
    authorizationParams={{
      redirect_uri: window.location.origin
    }}
  >
    <ShopContextProvider>
      <App/>
      </ShopContextProvider>
    </Auth0Provider>
);