import { useState } from 'react';
import styled from 'styled-components';
import Spinner from 'react-bootstrap/Spinner';
import Spacer from '../Spacer/Spacer';
import AlertDismissible from '../AlertDismissible/AlertDismissible';

const InputSideWrapper = styled.form`
  height: auto;
  padding-bottom: 100px;
  position: relative;
  padding: 10px 10px 100px 10px;
`;

const InputWrapper = styled.div`
  border: 2px solid transparent;
  width: 90%;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  color: #333;
  width: 100%;
  font-size: 15px;
  padding: 8px;
  border-bottom: 1px solid rgb(100, 21, 173);
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  outline: 0px transparent !important;
`;

const MessageInput = styled.textarea`
  width: 100%;
  color: #333;
  font-size: 15px;
  padding: 10px;
  border-bottom: 1px solid rgb(100, 21, 173);
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  outline: 0px transparent !important;
`;

const SubMitButton = styled.input`
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  background-color: rgb(255, 0, 0);
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 12px 25px 12px 24px;
  cursor: pointer;
`;

// const LoadingButton = styled.button`
//   position: absolute;
//   bottom: 20px;
//   right: 20px;
//   padding: 10px;
//   background-color: rgb(255, 0, 0);
//   color: #fff;
//   border: none;
//   border-radius: 5px;
//   padding: 12px 25px 12px 24px;
//   cursor: pointer;
// `;

const InputSide = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false); // State to control showing the alert
  

  const nameHandler = (e) => {
    setName(e.target.value);
  };

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };

  const phoneHandler = (e) => {
    setPhone(e.target.value);
  };
  
  const messageHandler = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);

    try {
      const response = await fetch('https://pr-web-405511.appspot.com/api/contact/addcontactus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, phone, message }),
      });

      if (response.ok) {
        setShowAlert(true); // Show the success alert
        setName('');
        setEmail('');
        setPhone('');
        setMessage('');
      } else {
        alert('Failed to submit form');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again later.');
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <>
      <InputSideWrapper onSubmit={handleSubmit}>
        <Spacer/>
        <InputWrapper>
          <p>Name</p>
          <Input type="text" required placeholder="Enter your name" value={name} onChange={nameHandler} />
        </InputWrapper>
        <InputWrapper>
          <p>Email</p>
          <Input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={emailHandler}
            required
          />
        </InputWrapper>
        <InputWrapper>
          <p>Phone</p>
          <Input
            type="tel"
            required
            placeholder="Enter your phone number"
            value={phone}
            onChange={phoneHandler}
          />
        </InputWrapper>
        <InputWrapper>
          <p>Message</p>
          <MessageInput
            required
            placeholder="Write your message"
            value={message}
            onChange={messageHandler}
          />
        </InputWrapper>
        {buttonLoading ? (
          <Spinner animation="border" />
        ) : (
          <SubMitButton type="submit" value="Send Message" />
        )}
      </InputSideWrapper>
      
      {showAlert && (
        <AlertDismissible
          show={showAlert}
          variant="success"
          heading="Message Sent Successfully"
          message="Your message has been sent successfully."
          onClose={() => setShowAlert(false)}
        />
      )}
    </>
  );
};

export default InputSide;
