import Spinner from "react-bootstrap/esm/Spinner";
import Navbar from "./Components/Navbar/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// Import Auth0ProviderWithHistory component
import { AuthenticationGuard } from "./Components/authentication-guard";
import Shop from "./Pages/Shop";
import Cart from "./Pages/Cart";
import Product from "./Pages/Product";
import FormPage from "./Pages/FormPage";
import SuccessPage from "./Pages/SuccessPage";
import Footer from "./Components/Footer/Footer";
import ShopCategory from "./Pages/ShopCategory";
import Shipping from "./Pages/Shipping";
import { useAuth0 } from "@auth0/auth0-react";
import ShippingChoice from "./Pages/ShippingChoice";
import SearchPage from "./Pages/SearchPage";
import Gallery from "./Pages/Gallery";
import ProfilePage from "./Pages/ProfilePage";
import UpdateProfile from "./Pages/UpdateProfile";



function App() {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <div className="page-layout"><Spinner /></div>;
  }



  return (

    <div>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Shop gender="all" />} />
          <Route path="/mens" element={<ShopCategory banner={"https://res.cloudinary.com/dn3lhtixp/image/upload/v1711382617/2_nt9n1b.png"} category="men" />} />
          <Route path="/womens" element={<ShopCategory banner={"https://res.cloudinary.com/dn3lhtixp/image/upload/v1711382616/1_swt2lq.png"} category="women" />} />
          <Route path="/accesories" element={<ShopCategory banner={"https://res.cloudinary.com/dn3lhtixp/image/upload/v1711382906/4_iqpef2.png"} category="accesories" />} />
          <Route path="/shoes" element={<ShopCategory banner={"https://res.cloudinary.com/dn3lhtixp/image/upload/v1711382906/4_iqpef2.png"} category="shoes" />} />
          <Route path='/product' element={<Product />}>
            <Route path=':productId' element={<Product />} />
          </Route>
          <Route path="/cart" element={<AuthenticationGuard component={Cart} />} />
          <Route path="/contactform" element={<FormPage />} />
          <Route
            path="/profile"
            element={<AuthenticationGuard component={ProfilePage} />}
          />
          <Route
            path="/updateprofile"
            element={<AuthenticationGuard component={UpdateProfile} />}

          />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/formsuccess" element={<SuccessPage />} />
          <Route path="/shipping" element={<Shipping />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/shipping-choice" element={<ShippingChoice />} />
          <Route path="/login" />
        </Routes>
        <Footer />
      </Router>
    </div>

  );
}

export default App;