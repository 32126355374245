import React, { useState , useEffect} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "./ShippingForm.css";

const ShippingForm = () => {
  const { user } = useAuth0();
  const [addressFromServer, setAddressFromServer] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    street1: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });
  const userId = user && user.sub ? user.sub : null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const saveAddressResponse = async (userId, formAddressTo) => {
    try {
      const response = await fetch(`https://pr-web-405511.appspot.com/api/shippingAddress/${userId}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("auth-token"),
        },
        body: JSON.stringify({ userId: userId, formAddressTo }),
      });
  
      // Return the response object regardless of whether it's OK or not
      return response;
    } catch (error) {
      console.error('Error storing address:', error);
      // Return null or any other value to indicate failure
      return null;
    }
  };
  const getAddressResponse = async (userId) => {
    try {
      const response = await fetch(`https://pr-web-405511.appspot.com/api/shippingAddress/find/${userId}`, 
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          }
        }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch line items");
        }
        const data = await response.json();
        console.log("Address Response:", data);
        setAddressFromServer(data);
      } catch (error) {
        console.error("Error fetching address:", error);
      }
    };
  
  useEffect(() => {
    if (userId) {
      getAddressResponse(userId);
    }else{
      console.log("No address found(No User)");
    }
  }, [userId]);

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Prepare the addressTo object using form data
      const formAddressTo = {
        name: formData.name,
        street1: formData.street1,
        city: formData.city,
        state: formData.state,
        zip: formData.zip,
        country: formData.country
      };
      console.log("formAddressTo:", formAddressTo);
      // Extract userId from the user object provided by Auth0
      const userId = user && user.sub ? user.sub : null;
  
      if (!userId) {
        throw new Error('User ID not found');
      }
  
      // Save the address to the server
      const saveAddress = await saveAddressResponse(userId, formAddressTo);
      console.log(saveAddress);
  
      // Check if address was saved successfully
      if (!saveAddress.ok) {
        throw new Error('Failed to save shipping address');
      }
  
      // Make a POST request to get shipping rates
      const response = await fetch("https://pr-web-405511.appspot.com/get-shippo-rates", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          addressTo: addressFromServer, // Pass addressTo obtained from the server
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to get shipping options');
      }
  
      const shippingOptions = await response.json();
      console.log("Shipping Options:", shippingOptions);
  
      // Redirect to the /shipping-choice route after successful form submission
      window.location.href = "/shipping-choice";
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  
  return (
    <div className="form-container">
      <h2>Shipping Information</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <label htmlFor="fullName">Full Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-row">
          <label htmlFor="addressLine1">Street</label>
          <input
            type="text"
            id="street1"
            name="street1"
            value={formData.street1}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-row">
          <label htmlFor="addressLine1">City</label>
          <input
            type="text"
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-row">
          <label htmlFor="addressLine2">State</label>
          <input
            type="text"
            id="state"
            name="state"
            value={formData.state}
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label htmlFor="zip">Zip/Postal Code</label>
          <input
            type="text"
            id="zip"
            name="zip"
            value={formData.zip}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-row">
          <label htmlFor="state">Country</label>
          <input
            type="text"
            id="country"
            name="country"
            value={formData.country}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-row submit-button">
          <input type="submit" value="Submit" />
        </div>
      </form>
    </div>
  );
};

export default ShippingForm;
