import React, { useState, useEffect } from 'react';
import Item from '../Item/Item';
import './Popular.css'; // Import your CSS file
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';  
const Popular = () => {
  const [womenProducts, setWomenProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWomenProducts = async () => {
      try {
        const response = await fetch('https://pr-web-405511.appspot.com/api/products/women');
        if (!response.ok) {
          throw new Error('Failed to fetch women\'s products');
        }
        const data = await response.json();
        setWomenProducts(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchWomenProducts();
  }, []);

  if (loading) {
    return (
      <div>
        {loading ? <LoadingSpinner /> : <div>Your content when not loading</div>}
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Function to shuffle array
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  // Select three random products from the women's collection
  const randomThreeWomenProducts = shuffleArray(womenProducts).slice(0, 4);

  return (
    <div className='popular'>
      <h1>POPULAR IN WOMEN</h1>
      <hr />
      <div className="popular-item">
        {randomThreeWomenProducts.map((item, i) => (
          <Item
            key={item._id}
            id={item._id}
            name={item.title}
            image={item.imageUrl}
            new_price={item.price}
          />
        ))}
      </div>
    </div>
  );
};

export default Popular;
