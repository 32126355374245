import React from 'react'
import CartItems from '../Components/CartItems/CartItems'
import DialogflowMessenger from '../Components/DialogFlowMessenger/DialogFlowMessenger'

const Cart = () => {
  return (
    <div>
      <CartItems/>
      <DialogflowMessenger/>
    </div>
  )
}

export default Cart
