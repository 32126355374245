import React, { useEffect, useState } from "react";
import "./CSS/ShopCategory.css";
import { useShopContext } from '../Context/ShopContext';
import dropdown_icon from "../Components/Assets/dropdown_icon.png";
import Item from "../Components/Item/Item";
import DialogflowMessenger from "../Components/DialogFlowMessenger/DialogFlowMessenger";
import Spinner from "react-bootstrap/esm/Spinner";

const ShopCategory = (props) => {
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { setSelectedProduct } = useShopContext();

  const handleProductSelection = (product) => {
    console.log("Selecting product:", product);
    setSelectedProduct(product); // Set the selected product in the context
    console.log("Selected product:", product);
  };
  
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          `https://pr-web-405511.appspot.com/api/products/${props.category}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch products");
        }
        const data = await response.json();
        setAllProducts(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProducts();
  }, [props.category]);

  if (loading) {
    return (
      <div>
        {loading ? <Spinner animation="border" /> : <div>Your content when not loading</div>}
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <div className="shopcategory">
      <img src={props.banner} className="shopcategory-banner" alt="" />
      <div className="shopcategory-indexSort">
        <p>
          <span>Showing 1 - {allProducts.length}</span> out of{" "}
          {allProducts.length} Products
        </p>
        <div className="shopcategory-sort">
          Sort by <img src={dropdown_icon} alt="" />
        </div>
      </div>
      <div className="shopcategory-products">
        {allProducts.map((item) => (
          <Item
            key={item._id}
            id={item._id}
            name={item.title} // Passing title as name
            image={item.imageUrl} // Passing imageUrl as image
            new_price={item.price} // Passing price as new_price
            onSelect={() => handleProductSelection(item)}
          />
        ))}
      </div>
      <div><DialogflowMessenger/></div>
    </div>
    
  );
};

export default ShopCategory;
