import React from "react";
import "./Offers.css";


const Offers = () => {
  return (
    <div className="offers">
      <div className="offers-left">
        <h1>Designed By</h1>
        <h1>Vetement Designer</h1>
        <p>Stargaze Collection</p>
        {/* <button>View Collection</button> */}
      </div>
      <div className="offers-right">
        <img src={"https://res.cloudinary.com/dn3lhtixp/image/upload/v1711244788/Untitled_design_1_tphxzv.png"} alt="" />
      </div>
    </div>
  );
};

export default Offers;
