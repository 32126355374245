import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const ShopContext = createContext(null);

export const useShopContext = () => useContext(ShopContext);

const ShopContextProvider = (props) => {
  const [products, setProducts] = useState([]);
  const [cartItems, setCartItems] = useState({});
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [userId, setUserId] = useState(null); // State to store the user ID
  const { isAuthenticated, isLoading, user } = useAuth0();
  

  useEffect(() => {
    
    if (isAuthenticated && user) {
      // Set the userId state with the sub property from the user object
      setUserId(user.sub);
      
      fetchProducts();
    }
  }, [isAuthenticated, user]);

  const fetchProducts = async () => {
    try {
      const response = await fetch("https://pr-web-405511.appspot.com/api/products");
      if (!response.ok) {
        throw new Error("Failed to fetch products");
      }
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const getTotalCartItems = useCallback(async () => {
    let totalItem = 0;

    

    try {
      const response = await fetch(
        `https://pr-web-405511.appspot.com/api/cart/find/${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch cart");
      }

      const cartData = await response.json();
      
      

      // Sum up the quantities of all products in the cart
      cartData.forEach(item => {
        totalItem += item.products.reduce((acc, curr) => acc + curr.quantity, 0);
      });

      
      return totalItem;
    } catch (error) {
      console.error("Error fetching cart:", error);
      // Assuming you want to return 0 if there's an error
      return totalItem;
    }
  }, [userId]);




  // Update useEffect to include getTotalCartItems as a dependency
  useEffect(() => {
    const fetchTotalCartItems = async () => {
      if (isAuthenticated && userId) {
        try {
          const totalItems = await getTotalCartItems(); // Call getTotalCartItems when userId is available
          console.log(totalItems) 
        } catch (error) {
          console.error('Error fetching total cart items:', error);
        }
      }
    };

    fetchTotalCartItems();
  }, [isAuthenticated, userId, getTotalCartItems]); 


  // Function to fetch cart items from the server
  const fetchCartItems = async () => {
    try {
      const response = await fetch(
        `https://pr-web-405511.appspot.com/api/cart/find/${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch cart");
      }

      const cartData = await response.json();
      return cartData; // Return the response data
    } catch (error) {
      console.error("Error fetching cart items:", error);
    }
  };

  useEffect(() => {
    const fetchTotalCartItems = async () => {
      if (isAuthenticated && userId) {
        try {
          const totalItems = await getTotalCartItems(); // Call getTotalCartItems when userId is available
          console.log(totalItems) 
        } catch (error) {
          console.error('Error fetching total cart items:', error);
        }
      }
    };
  
    fetchTotalCartItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, userId]); // Ignore warning for fetchTotalCartItems
  
  const addToCart = async (itemId, selectedSize ) => {
    try {
      const response = await fetch("https://pr-web-405511.appspot.com/api/cart/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("auth-token"),
        },
        body: JSON.stringify({ userId: userId, cartItem: itemId, quantity: 1 , size: selectedSize }),
      });
      if (!response.ok) {
        throw new Error("Failed to add item to cart");
      }
      const data = await response.json();
      return data; // Return the response data
    } catch (error) {
      console.error("Error adding item to cart:", error);
      throw error; // Re-throw the error
    }
  };
  const getTotalCartAmount = () => {
    let totalAmount = 0;
  
    for (const item in cartItems) {
      if (cartItems[item] > 0) {
        let itemInfo = products.find((product) => product.id === Number(item));
        totalAmount += cartItems[item] * itemInfo.new_price;
      }
    }
    
    return totalAmount;
  };
  const removeFromCart = (itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] - 1 }));
    if (userId) {
      fetch(`https://pr-web-405511.appspot.com/api/cart/${userId}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("auth-token"),
        },
        body: JSON.stringify({ itemId: itemId }),
      })
        .then((resp) => {
          if (!resp.ok) {
            throw new Error("Failed to remove item from cart");
          }
          return resp.json();
        })
        .then((data) => {
          console.log(data);
          // Handle successful response here, if needed
        })
        .catch((error) => {
          console.error("Error removing item from cart:", error);
          // Handle error here, if needed
        });
    }
  };

  const contextValue = {
    products,
    cartItems,
    selectedProduct,
    setSelectedProduct,
    addToCart,
    removeFromCart,
    getTotalCartAmount, // Include getTotalCartAmount in the context value
    getTotalCartItems,
    isAuthenticated,
    user,
    isLoading,
    userId,
    fetchCartItems,
  };
  
  return (
    <ShopContext.Provider value={contextValue}>
      {props.children}
    </ShopContext.Provider>
  );
};

export default ShopContextProvider;
