import React, { useState } from 'react';
import './NewsLetter.css';

const NewsLetter = () => {
  const [email, setEmail] = useState('');

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://pr-web-405511.appspot.com/api/subscriber/addsubscriber', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        alert('Subscription successful!');
        setEmail(''); // Clear the input field after successful subscription
      } else {
        alert('Subscription failed. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  return (
    <div className='newsletter'>
      <p>Subscribe to our newsletter and stay updated.</p>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder='Enter your email'
          value={email}
          onChange={handleInputChange}
          className='newsletter-input' // Apply class for styling
        />
        <button className='newsletter-button' type="submit">Subscribe</button>
      </form>
    </div>
  );
};

export default NewsLetter;
