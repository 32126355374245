import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

function AlertDismissible({ show, variant, heading, message, onClose }) {
  return (
    <>
      <Alert show={show} variant={variant}>
        <Alert.Heading>{heading}</Alert.Heading>
        <p>{message}</p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={onClose} variant="outline-success">
            Close me
          </Button>
        </div>
      </Alert>
    </>
  );
}

export default AlertDismissible;
